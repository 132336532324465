.hero {
  padding-top: 72px;
  padding-bottom: 56px;

  &.-dark {
    background-color: map-get($colors, black500);

    & .paragraph,
    & .title {
      color: white;
    }
  }

  & .paragraph:last-child {
    margin-bottom: 0;
  }
}
