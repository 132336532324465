.card {
  position: relative;
  z-index: 1;
  border-radius: 4px;
  flex-grow: 1;
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 4px -1px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;

  &:hover,
  &:focus-within {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2), 0 1px 6px -1px rgba(0, 0, 0, 0.12);
  }

  &__link {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;

    & span {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap;
    }
  }

  &__media {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
    background: map-get($colors, black200);

    & img {
      position: absolute;
      top: 0;
      bottom: 0;
      object-fit: cover;
    }
  }

  &__body {
    padding: 24px;

    & > :last-child {
      margin-bottom: 0;
    }
  }

  &__featureList {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    & li {
      @extend .paragraph;
      @extend .-small;
      margin-bottom: 0;
      margin-right: 24px;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 8px;
        display: inline-block;
        vertical-align: middle;
        background-color: map-get($colors, blue500);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
