// Colors
$colors: (
  white: #ffffff,
  black500: #0e0f0f,
  black400: #1d1d1b,
  black300: #5f5f5f,
  black200: #9e9e9e,
  black50: #f5f5f5,
  blue50: #f0f7fe,
  blue300: #3c96ed,
  blue400: #1479db,
  blue500: #0f5ba5,
  blue600: #0c4781,
  blue700: #08335d,
  red500: #f00000,
);

// Spacings
$spacings: (
  0: 0,
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 24,
  6: 32,
  7: 40,
  8: 48,
  9: 56,
  10: 64,
  11: 72,
  12: 80,
);

// Fonts
$fonts: (
  primary: #{'IBM Plex Sans', Helvetica, sans-serif},
  mono: #{'IBM Plex Mono', monospace},
);

// Breakpoints
$breakpoints: (
  mobile: 0,
  tablet: 520px,
  desktop: 960px,
);

// Tweakpoints
$tweakpoints: ();

// Grid
$grid-width: 100%;
$grid-max-width: 1230px;
$grid-columns: 12;
$grid-columns-width: (100 / $grid-columns) !default;
$grid-gutter: (
  desktop: 16px,
  tablet: 16px,
  mobile: 8px
);
$grid-column-gutter: (
  desktop: 16px,
  tablet: 12px,
  mobile: 6px
);

// Easings
$easings: (
  // In
  easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
  easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19),
  easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22),
  easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06),
  easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715),
  easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035),
  easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335),
  easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045),
  // Out
  appleOut: cubic-bezier(0.4, 0.01, 0.165, 0.99),
  swiftOut: cubic-bezier(0.55, 0, 0.1, 1),
  easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1),
  easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1),
  easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1),
  easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1),
  easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1),
  easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275),
  // In out
  easeInOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  easeInOutSine: cubic-bezier(0.39, 0.575, 0.565, 1),
  easeInOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  easeInOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1),
  easeInOutQuint: cubic-bezier(0.23, 1, 0.32, 1),
  easeInOutExpo: cubic-bezier(0.19, 1, 0.22, 1),
  easeInOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1),
  easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55)
);
