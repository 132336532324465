.paragraph {
  margin-top: 0;
  margin-bottom: 2em;
  font-family: map-get($fonts, primary);
  font-size: 1.125rem;
  line-height: 1.5em;
  color: map-get($colors, black400);

  &.-excerpt {
    font-size: 1.5rem;
  }

  &.-small {
    font-size: 1rem;
  }

  &.-white {
    color: white;
  }

  &.-gray {
    color: map-get($colors, black50);
  }

  &.-noMargin {
    margin-bottom: 0;
  }
}
