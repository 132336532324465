.footer {
  padding-top: 32px;
  padding-bottom: 32px;
  margin-top: auto;
  background-color: map-get($colors, black400);

  & .paragraph {
    @include breakpoint(tablet) {
      margin-bottom: 0;
    }
  }

  & .grid__column:last-child {
    margin-left: auto;
  }
}
