.section {
  padding-top: 64px;
  padding-bottom: 64px;

  &.-large {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  &.pt-0 {
    padding-top: 0;
  }

  &.-black {
    background-color: map-get($colors, black500);
  }

  &.-lightGray {
    background-color: map-get($colors, black50);
  }

  &.-coverTop {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      height: 180px;
      background-color: map-get($colors, black500);
    }
  }
}
