.nav {
  display: flex;

  &__button {
    position: relative;
    z-index: 10;
    appearance: none;
    padding: 0;
    border: none;
    font-size: 24px;
    color: map-get($colors, black900);
    box-shadow: none;
    background: transparent;

    & svg:last-child {
      display: none;
    }

    &.-expanded {
      & svg:first-child {
        display: none;
      }

      & svg:last-child {
        display: block;
      }
    }

    @include breakpoint(desktop) {
      display: none;
    }
  }

  &__list {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 24px;
    overflow-y: auto;
    background: white;

    &.-expanded {
      display: flex;
    }

    @include breakpoint(desktop) {
      position: static;
      display: flex;
      padding: 0;
      flex-direction: row;
    }
  }

  &__item {
    display: flex;
    max-width: 320px;
    margin-bottom: 8px;

    @include breakpoint(desktop) {
      margin-bottom: 0;

      &.-hideDesktop {
        display: none;
      }

      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }

  &__link {
    position: relative;
    display: block;
    line-height: 40px;
    font-size: 1.5rem;
    font-weight: 500;
    color: map-get($colors, black300);
    text-decoration: none;

    @include breakpoint(desktop) {
      display: flex;
      align-items: center;
      line-height: 1;
      font-size: 1rem;
    }

    &.-current,
    &.current-page-ancestor {
      color: map-get($colors, black500);

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 4px;
        background-color: map-get($colors, blue300);

        @include breakpoint(desktop) {
          height: 3px;
        }
      }
    }
  }
}
