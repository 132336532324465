.wpcf7 {
  max-width: 328px;
  width: 100%;

  & .wpcf7-response-output {
    margin: 0;
  }

  & p,
  & fieldset {
    margin-bottom: 24px;
  }

  & fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  & legend:not(.title) {
    margin-bottom: 16px;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.78;
    color: map-get($colors, black500);
  }

  & label {
    font-size: 14px;
    color: map-get($colors, black500);
  }

  & .wpcf7-text,
  & .wpcf7-number,
  & .wpcf7-textarea {
    appearance: none;
    padding: 16px;
    border: none;
    width: 100%;
    border-bottom: solid 1px map-get($colors, black200);
    font-size: 1rem;
    line-height: normal;
    color: map-get($colors, black500);
    box-shadow: none;
    background: map-get($colors, black50);

    &:focus {
      outline: none;
      border-color: map-get($colors, blue500);
    }

    &[aria-invalid='true'] {
      border-color: map-get($colors, red500);
    }
  }

  & .wpcf7-radio {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    & input:focus {
      outline: none;
    }
  }

  & .wpcf7-list-item {
    margin: 0;
  }

  & .wpcf7-list-item-label {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: map-get($colors, black300);

    &::before {
      content: none;
    }
  }

  & .wpcf7-submit {
    @extend .button;
  }
}
