.header {
  display: flex;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 4px -1px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;

  & .grid {
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: nowrap;
  }

  & .grid__column {
    width: auto;
    flex-shrink: 0;
  }

  &__logo {
    padding: 12px 0;

    @include breakpoint(tablet) {
      padding: 24px 0;
    }

    & img {
      height: 40px;
      width: auto;
    }
  }
}
