// Note! Only temporary and WIP style should be in this file

.cover {
  position: relative;
  padding-top: 77px;

  &__background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 320px;
    object-fit: cover;
    background: map-get($colors, black500);

    & img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__content {
    padding-top: 32px;
    background: white;
  }
}

.tile {
  position: relative;
  display: block;
  padding: 70px 32px 32px;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  background: map-get($colors, black500);

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image:
        linear-gradient(
          to bottom,
          rgba(14, 15, 15, 0) -47%,
          rgba(14, 15, 15, 0.02) -34%,
          rgba(14, 15, 15, 0.06) -22%,
          rgba(14, 15, 15, 0.12) -11%,
          rgba(14, 15, 15, 0.2) -1%,
          rgba(14, 15, 15, 0.29) 8%,
          rgba(14, 15, 15, 0.39) 18%,
          rgba(14, 15, 15, 0.5) 27%,
          rgba(14, 15, 15, 0.61) 36%,
          rgba(14, 15, 15, 0.71) 45%,
          rgba(14, 15, 15, 0.8) 55%,
          rgba(14, 15, 15, 0.88) 65%,
          rgba(14, 15, 15, 0.95) 76%,
          rgba(14, 15, 15, 0.98) 87%,
          #0e0f0f
        );
    }

    & img {
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
  }
}
