.title {
  display: block;
  margin: 0 0 0.4em;
  font-family: map-get($fonts, primary);
  font-weight: 700;
  color: map-get($colors, black500);
  line-height: 1.2em;

  &.-inverted {
    color: #ffffff;
  }

  &.-h1 {
    margin-bottom: 0.3333333333em;
    font-size: 3rem;
  }

  &.-h2 {
    font-size: 2rem;
  }

  &.-h3 {
    font-size: 1.5rem;
  }

  &.-h4 {
    margin-bottom: 0.444444em;
    font-size: 1.125rem;
    line-height: 1.6em;
  }

  &.-pre {
    margin-bottom: 32px;
    font-family: map-get($fonts, mono);
    font-weight: 400;
  }
}
