
// Margin/spacing util
.m {
  $directions: (
    top: t,
    right: r,
    bottom: b,
    left: l,
  );

  @each $breakpoint, $_ in $breakpoints {
    @each $index, $value in $spacings {
      @each $direction-long, $direction-short in $directions {
        &#{$direction-short}-#{$breakpoint}-#{$index} {
          @include breakpoint($breakpoint) {
            margin-#{$direction-long}: #{$value / 10}rem;
          }
        }
      }
    }
  }
}

// Margin/spacing util
.p {
  $directions: (
    top: t,
    right: r,
    bottom: b,
    left: l,
  );

  @each $breakpoint, $_ in $breakpoints {
    @each $index, $value in $spacings {
      @each $direction-long, $direction-short in $directions {
        &#{$direction-short}-#{$breakpoint}-#{$index} {
          @include breakpoint($breakpoint) {
            margin-#{$direction-long}: #{$value / 10}rem;
          }
        }
      }
    }
  }
}

.flex {
  display: flex;
}

.w-100 {
  width: 100%
}
