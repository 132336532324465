.link {
  color: map-get($colors, blue500);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.-standalone {
    font-family: map-get($fonts, mono);
    font-weight: 600;
    font-size: 0.875rem;
  }
}
