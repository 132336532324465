html {
  font-family: map-get($fonts, primary);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

a {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
}

b,
strong {
  font-weight: bold;
  line-height: inherit;
}

i,
em {
  font-style: italic;
  line-height: inherit;
}
