.button {
  @include reset-button;
  padding: 13px 16px;
  border-radius: 2px;
  font-family: map-get($fonts, mono);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  background-color: map-get($colors, blue500);

  // &:focus,
  // &:hover {
  //   background-color: darken(map-get($colors, blue500), 6%);
  // }

  // &:active {
  //   background-color: map-get($colors, blue500);
  // }
}
