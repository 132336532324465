*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  min-width: 320px;
  cursor: default;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &::after {
    display: none;

    @each $breakpoint, $value in $breakpoints {
      @include breakpoint($breakpoint) {
        content: '#{$breakpoint}';
      }
    }
  }
}

img {
  display: inline-block;
  vertical-align: middle;
  height: auto;
  max-width: 100%;
}

:focus {
  outline: dotted thin;
}

:hover,
:active {
  outline: 0;
}

.wp-block-image {
  margin-left: 0;
  margin-right: 0;
}
